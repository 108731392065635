import { ElMessage, ElMessageBox } from "element-plus";

export default {
    async confirm_modal(message='是否確定儲存變更？', show=true, catch_message="取消儲存"){
        try{
            await ElMessageBox.confirm(
                message,
                '系統訊息',
                {
                    confirmButtonText: '確定',
                    cancelButtonText: '取消',
                    type: 'info',
                    confirmButtonClass: 'el_confirm_button'
                }
            )
            if(show){
                ElMessage({
                    type: 'success',
                    message: '成功！'
                })
            }
            return true
        }
        catch{
            ElMessage({
                type: 'info',
                message: catch_message
            })
            return false
        }
    },
    date_to_str(date=new Date()){
        let year = date.getFullYear()
        let month = date.getMonth() + 1 // JavaScript 的月份是從 0 開始計算的，所以我們需要加 1

        // 確保月份是兩位數的格式
        if (month < 10) {
            month = '0' + month
        }

        let formattedDate = year + '-' + month
        return formattedDate
    }
}