import { createStore } from 'vuex'
import i18n from '@/lang'

export default createStore({
  state: {
    isMobile: false,
    user_data: {
      level: 1
    },
    language: navigator.language || navigator.userLanguage
  },
  getters: {
  },
  mutations: {
    setMobile(state, is_mobile){
      state.isMobile = is_mobile
    },
    set_user_data(state, data){
      state.user_data = data
    },
    setLang(state, lang){
      state.language = lang
      i18n.global.locale = lang;
      localStorage.setItem('cs_vue_language', lang)
    }
  },
  actions: {
  },
  modules: {
  }
})
