import { createRouter, createWebHistory } from 'vue-router'
import axios from "axios"

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/layout/body.vue'),
    redirect: "dashboard",
    children: [
      {
        path: '/dashboard',
        name: '首頁',
        component: () => import('@/pages/dashboard.vue')
      },
      {
        path: '/dataManage',
        name: '資料管理',
        component: () => import('@/pages/dataManage.vue')
      },
      {
        path: '/setting',
        name: '設定',
        component: () => import('@/pages/setting.vue')
      },
      {
        path: '/testNotify',
        name: '測試頁面',
        component: () => import('@/pages/testNotify.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login.vue')
  },
  {
    path: "/logout",
    component: {
      render(){
        console.log()
        axios.get("/logout")
        .finally(
          function(){
            window.location.href = '/login'
          }
        )
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
